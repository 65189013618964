import { ref } from "vue";
import detector from "../util/changeDetector"
import { io } from 'socket.io-client'

export function useChangeTracker(pageName, itemId = "", socketSyncEnabled = false) {
    let initialState = null;
    let currentState = null;
    let socket;

    const isDirty = ref(false); //is page edited by user (unsaved)
    const isSync = ref(true);  //is page edited and saved by another user
    const changes = ref([])  //changes to show

    if (socketSyncEnabled) {
        socket = io(process.env.VUE_APP_BACKEND_URL)
        socket.on('updated', (data) => {
            if (data.socketId === socket.id)
                return

            if (data.page !== pageName)
                return

            if (data.itemId !== itemId)
                return

            if (data.data) {
                changes.value = detector.detectRecursive(initialState, data.data, pageName);
                isSync.value = false;
            }
        });
    }

    function updateTrackerState(state) {
        if (!state)
            return;
        state = JSON.parse(JSON.stringify(state));
        if (!initialState) {
            initialState = state;
            return;
        }

        currentState = state;
        checkChanges();
        isDirty.value = changes.value.length !== 0;

    }

    function checkChanges() {
        if (!initialState || !currentState)
            return []
        let result = detector.detectRecursive(initialState, currentState, pageName)
        changes.value = result
        return result;
    }

    function onChangesSubmitted(itemId = "") {
        initialState = currentState;
        if (socketSyncEnabled) {
            socket.emit("update", {
                socketId: socket.id,
                page: pageName,
                data: currentState,
                itemId: itemId
            })
        }
    }

    return { isDirty, isSync, updateTrackerState, changes, checkChanges, onChangesSubmitted }
}