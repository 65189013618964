<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :class="size">
          <div class="modal-header">
            <slot name="header"> default header </slot>
          </div>

          <div class="modal-body">
            <slot name="body"> default body </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
                <Button size="sm" text="Close" type="info" :onClick="() => $emit('close')" />
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Button from './Button.vue'
export default {
  name: "Modal",
  components: {
    Button
  },
  props: {
    size: {
        type: String,
        required: false,
        default: 'md'
    }
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border-radius: 0.25rem;
}

.modal-container.lg {
  width: 80% !important;
}

.modal-container.md {
  width: 60% !important;
}

.modal-container.sm {
  width: 40% !important;
}

.modal-header {
  font-weight: bold;
  font-size: 1.4rem;
  font-family: "Roboto Mono", monospace;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
  max-height: 70vh;
  overflow-y: scroll;
}
.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}

.modal-footer {
    display: flex;
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-end;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>