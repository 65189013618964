<template>
  <modal v-if="isOpen" @close="() => onReject()" size="md">
    <template v-slot:header>{{ mode === 0 ? 'Confirm Submission' : 'You are out of sync!' }}</template>
    <template v-slot:body>
      <div class="confirm-popup-body">
        <p v-if="mode === 0">Your changes are listed below:</p>
        <p v-if="mode === 1">Somebody edited this page. Click Ok to get new data. Changes are listed below:</p>
        <ul v-if="changes.length > 0">
          <li v-for="(change, index) of changes" v-bind:key="index">
            {{ change }}
          </li>
        </ul>
        <div v-else>
          <p>Are you sure you want to submit your changes?</p>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="confirm-popup-footer">
        <Button
          :text="mode === 0 ? 'Submit' : 'Ok'"
          :onClick="() => onApprove()"
          :disabled="false"
          :size="sm"
          type="success"
        />
        <Button
          text="Cancel"
          :onClick="() => onReject()"
          :disabled="mode === 1"
          :size="sm"
          type="error"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Button from "./Button.vue";
import Modal from "./Modal.vue";

export default {
  components: {
    Modal,
    Button,
  },
  props: {
    mode: {
      type: Number,
      required: false,
      default: 0,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    changes: {
      type: Array,
      required: false,
      default: () => [],
    },
    onApprove: {
      type: Function,
      required: true,
    },
    onReject: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>
.confirm-popup-body {
  text-align: left;
}

.confirm-popup-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
}
</style>