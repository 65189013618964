import { ref } from "vue";

export function useModal() {
    const modalState = ref(false);
    const modalMode = ref(0);  //optional, use if you want to trigger different types of modals from the same component

    function openModal(mode = 0) {
        modalState.value = true;
        modalMode.value = mode;
    }

    function closeModal() {
        modalState.value = false;
    }

    return { modalState, modalMode, openModal, closeModal }
}